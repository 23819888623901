import { Route, Routes } from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Pages/Home";
import { ParallaxProvider } from "react-scroll-parallax";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";

function App() {
  return (
    <>
      <ParallaxProvider>
        <Header />
        <PrimeReactProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          {/* <Footer /> */}
        </PrimeReactProvider>
      </ParallaxProvider>
    </>
  );
}

export default App;
