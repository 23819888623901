import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import Section from "../Section";
import Button from "../Button";
import "./header.scss";

export default function Header() {
  const [mobileToggle, setMobileToggle] = useState(false);

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(
      "header.header-section.cs-site_header.cs-style1.cs-sticky-header"
    );
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  useEffect(() => {
    document.body.classList.add("cs-light");

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const linkForm = "https://form.typeform.com/to/p4HJDyn7";

  return (
    <>
      <Section
        tag="header"
        className="header-section cs-site_header cs-style1 cs-sticky-header"
      >
        <Section className="cs-main_header">
          <Section className="container">
            <Section className="cs-main_header_in">
              <Section className="cs-main_header_left">
                <Link to="/" className="cs-site_branding cs-accent_color">
                  {/* <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="cs-hide_dark logo-top"
                  />
                  <img
                    src="/images/logo.png"
                    alt="Logo"
                    className="cs-hide_white logo-top"
                  /> */}
                  {/* <h1 className="titulo">
                    Convenção
                    <br /> Cheirin Bão 2023
                    <br />
                    <span>Juntos Construímos o futuro!</span>
                  </h1> */}
                  <img
                    src="/images/logos/convencao.png"
                    alt="Logo"
                    className=""
                  />
                </Link>
              </Section>
              <Section className="cs-main_header_center">
                <Section className="cs-nav">
                  <Section className="cs-btn btn-head">
                    <Button
                      btnLink={linkForm}
                      btnText="Lista de espera!"
                      variant="cs-color2"
                    />
                  </Section>

                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Section>
              </Section>
              <Section className="cs-nav">
                <Section className="cs-main_header_right">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <ScrollLink
                        to="faq"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}
                        onClick={() => setMobileToggle(false)}
                      >
                        Dúvidas frequentes
                      </ScrollLink>
                    </li>
                    <li>
                      <ScrollLink
                        to="windsor"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}
                        onClick={() => setMobileToggle(false)}
                      >
                        Onde me hospedar?
                      </ScrollLink>
                    </li>
                  </ul>
                </Section>
              </Section>
            </Section>
          </Section>
        </Section>
      </Section>
    </>
  );
}
