import React from "react";
import { Parallax } from "react-parallax";
import parse from "html-react-parser";
import "./hero.scss";
import Section from "../Section";

export default function Hero({
  title,
  bgUrl,
  variant,
  bubble,
  overlay,
  bgOpacity,
}) {
  return (
    <Section
      tag="section"
      className={`cs-hero cs-style1 ${variant ? variant : ""}`}
      id="hero"
    >
      {overlay && (
        <Section className="cs-dark_overlay" style={{ opacity: overlay }} />
      )}
      <Section className="cs-hero_bg" style={{ opacity: bgOpacity }}>
        <Parallax bgImage={bgUrl} bgImageAlt="Hero" strength={300}></Parallax>
      </Section>
      <Section className="container">
        <Section className="cs-hero_text">
          <img
            src="/images/logos/convencao.png"
            alt="Logo"
            className="logo-hero"
          />

          <Section
            tag="h1"
            className="cs-hero_title cs-font_50 cs-font_30_sm cs-bold horizon titulo"
          >
            {parse(title)}
          </Section>
          <Section tag="p">Nos vemos na próxima edição!</Section>
        </Section>
      </Section>
      {bubble && (
        <Section id="background-wrap">
          <Section className="bubble x1"></Section>
          <Section className="bubble x2"></Section>
          <Section className="bubble x3"></Section>
          <Section className="bubble x4"></Section>
          <Section className="bubble x5"></Section>
          <Section className="bubble x6"></Section>
          <Section className="bubble x7"></Section>
          <Section className="bubble x8"></Section>
          <Section className="bubble x9"></Section>
          <Section className="bubble x10"></Section>
        </Section>
      )}
    </Section>
  );
}
