import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Hero from "../Hero";

import "../Header/header.scss";

export default function Home() {
  pageTitle("Convenção Cheirin Bão 2023");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title="A CONVENÇÃO CHEIRIN BÃO 2023 FOI INESQUECÍVEL!"
        mintNumber="23"
        mintMax="24"
        mintPrice="0.02"
        mintDeathLine="22 July"
        bgUrl="/images/convencao/end.jpg"
        heroImageUrl="/images/hero_img.svg"
        animatedUrl="/images/convencao/end.jpg"
        overlay="0.6"
        bgOpacity="0.4"
        //btnLink={linkForm}
      />
    </>
  );
}
